<template>
  <div>
    <v-container class="pb-0" v-for="proposal in proposals" :key="proposal.id">
      <ProductListSlider
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </v-container>
  </div>
</template>
<script>
import ProductListSlider from "@/components/product/ProductListSlider.vue";

export default {
  name: "ProposalProductGrid",
  components: {
    ProductListSlider
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
};
</script>
